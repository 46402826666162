import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const FaqPage = ({data}) => {
  const entry = data.markdownRemark
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full py-12 bg-orange">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12 pt-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          {entry.frontmatter.faqs.map((entry, i) => {
            return (
              <Disclosure as="div" key={`faq_${i}`} className="w-full mb-6 last:mb-0 group">
              {({ open }) => (
                <Disclosure.Button className="w-full p-6 border-2 border-grey-c group-hover:border-blue">
                  <dt>
                    <div className="text-left w-full flex justify-between items-start">
                      <h2 className="font-verdana font-bold text-blue text-base inline">
                        {entry.question}
                      </h2>
                      <span className="ml-6 h-6 flex items-center">
                        <ChevronDownIcon
                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transition transform inline text-blue')}
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-3 pr-24">
                    <div className="text-left font-verdana prose max-w-none leading-relaxed text-grey-6">
                      {entry.answer}
                    </div>
                  </Disclosure.Panel>
                </Disclosure.Button>
              )}
            </Disclosure>
          )})}
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default FaqPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        faqs {
					question
          answer
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`