import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Form from '../components/form'
import Seo from '../components/seo'
import { getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const ContactPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full py-12 bg-orange">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12 border-b-2 border-grey-c">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <Form />
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              <iframe width="100%" height="100%" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.5264938925525!2d174.72347321529014!3d-36.80590177994729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d38f0b022cd79%3A0x53d47bfbe971877!2sAction%20Glass%20Limited!5e0!3m2!1sen!2snz!4v1647910649912!5m2!1sen!2snz" allowFullScreen={false} loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ContactPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`