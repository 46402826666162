exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-entry-js": () => import("./../../../src/templates/entry.js" /* webpackChunkName: "component---src-templates-entry-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-list-faq-js": () => import("./../../../src/templates/list_faq.js" /* webpackChunkName: "component---src-templates-list-faq-js" */),
  "component---src-templates-list-gallery-js": () => import("./../../../src/templates/list_gallery.js" /* webpackChunkName: "component---src-templates-list-gallery-js" */),
  "component---src-templates-list-js": () => import("./../../../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-list-testimonials-js": () => import("./../../../src/templates/list_testimonials.js" /* webpackChunkName: "component---src-templates-list-testimonials-js" */),
  "component---src-templates-thanks-js": () => import("./../../../src/templates/thanks.js" /* webpackChunkName: "component---src-templates-thanks-js" */)
}

