import React from 'react'
import Footer from '../components/footer'
import Nav from '../components/nav'

const ErrorPage = () => {
	return (
    <div>
      <Nav />
      <div className="w-full py-12 bg-orange">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">Page not found</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12 border-b-2 border-grey">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-around gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose text-center">
                <p>We couldn’t find what you were looking for.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ErrorPage;
