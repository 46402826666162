import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import GoogleReviews from '../components/google_reviews'

const ListPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
  const allEntries = data.allMarkdownRemark
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full pt-24 pb-24 lg:pt-48 lg:pb-48 bg-white relative">
        <div className="absolute -inset-0">
          <GatsbyImage image={image} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="font-verdana font-bold">
              <h1 className="text-white text-4xl lg:text-8xl leading-none">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <GoogleReviews />
      <div className="w-full pb-12 pt-0 lg:pt-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex-none lg:flex justify-between gap-12 lg:gap-24">

            <div className="w-full lg:w-3/12">
              <div className="max-w-none font-verdana prose leading-relaxed">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>

            <div className="w-full pt-12 lg:pt-0 lg:w-9/12">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                {allEntries.edges.map( (entry, i) => {
                const childImage = getImage(entry.node.frontmatter.image)
                  return (
                    <div key={`child_${i}`} className="group font-verdana text-center">
                    <Link to={entry.node.fields.slug}>
                      <figure className="relative">
                        <div className="relative">
                          <GatsbyImage className="aspect-w-1 aspect-h-1" image={childImage} alt={entry.node.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                        </div>
                        <div className="absolute -inset-0 bg-black opacity-0 group-hover:opacity-50">
                          &nbsp;
                        </div>
                        <div className="absolute -inset-0">
                          <div className="flex items-center justify-center w-full h-full">
                            <span className="w-6/12 text-3xl leading-tight text-white font-bold inline-block group-hover:text-white">{entry.node.frontmatter.title}</span>
                          </div>
                        </div>
                      </figure>
                    </Link>
                  </div> 
                )})}
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ListPage;

export const query = graphql`
	query($slug: String!, $category: String!,) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
    allMarkdownRemark(filter: {frontmatter: {section: {eq: $category}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            heading
            image {
              childImageSharp {
                gatsbyImageData(width: 640)
              }
            }
          }
        }
      }
    }
	}
`