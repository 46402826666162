import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import Nav from '../components/nav'

const TestimonialsPage = ({data}) => {
  const entry = data.markdownRemark
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full py-12 bg-orange">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-6 pt-12 border-b-2 border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="text-center">
            {entry.frontmatter.testimonials.map((entry, i) => {
              return (
                <div key={`child_${i}`} className="group text-base font-verdana px-12 py-6 border-2 border-grey-c mb-6">
                  <p className="text-grey-6 leading-relaxed">{entry.text} <strong className="text-blue">&mdash; {entry.author}</strong></p>
                </div>
            )})}
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default TestimonialsPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        testimonials {
					text
          author
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`